'use strict'

angular
  .module 'mundoAdmin.assets'
  # .config ($stateProvider) ->
  #   $stateProvider
  #     .state 'assets',
  #       url: '/assets'
  #       parent: 'admin'
  #       views:
  #         '@admin':
  #           templateUrl: 'mundo-admin/assets/views/assets.tpl.html'
  #           controller: 'TabsCtrl'
  #           controllerAs: 'assetManagementCtrl'
  #       deepStateRedirect: { default: { state: 'assets.overview' } },

  #     .state 'assets.overview',
  #       url: '/overview'
  #       data:
  #         'selectedTab': 0
  #       views:
  #         'overview@assets':
  #           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
  #           controller: 'DataTableCtrl'
  #           controllerAs: 'listCtrl'
  #           resolve:
  #             entityManager: (AssetsOverviewManager) ->
  #               AssetsOverviewManager
  #             pageTitle: ->
  #               'app.admin.pageTitles.assets.assetsOverview'
  #             pageDescription: ->
  #               'app.admin.descriptions.assets.overviewAssets'
  #             deleteBoxTitle: ->
  #               'app.admin.actions.assets.deleteAssetOverview'
  #             deleteBoxBody: ->
  #               'app.admin.actions.assets.deleteAssetOverview'
  #             AddBoxTitle: ->
  #               'app.admin.actions.assets.addAssetOverview'
  #             editBoxTitle: ->
  #               'app.admin.actions.assets.editAssetOverview'
  #             loadFilterParams: ->
  #               {}

  #     .state 'assets.assets',
  #       url: '/assets'
  #       data:
  #         'selectedTab': 1
  #       views:
  #         'assets@assets':
  #           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
  #           controller: 'DataTableCtrl'
  #           controllerAs: 'listCtrl'
  #           resolve:
  #             entityManager: (AssetManager) ->
  #               AssetManager
  #             pageTitle: ->
  #               'app.admin.pageTitles.assets.assets'
  #             pageDescription: ->
  #               'app.admin.descriptions.assets.assets'
  #             deleteBoxTitle: ->
  #               'app.admin.actions.assets.deleteAsset'
  #             deleteBoxBody: ->
  #               'app.admin.actions.assets.deleteAsset'
  #             AddBoxTitle: ->
  #               'app.admin.actions.assets.addAsset'
  #             editBoxTitle: ->
  #               'app.admin.actions.assets.editAsset'
  #             loadFilterParams: ->
  #               {}

  #     .state 'assets.types',
  #       url: '/types'
  #       data:
  #         'selectedTab': 2
  #       views:
  #         'types@assets':
  #           templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
  #           controller: 'DataTableCtrl'
  #           controllerAs: 'listCtrl'
  #           resolve:
  #             entityManager: (AssetTypesManager) ->
  #               AssetTypesManager
  #             pageTitle: ->
  #               'app.admin.pageTitles.assets.types'
  #             pageDescription: ->
  #               'app.admin.descriptions.assets.types'
  #             deleteBoxTitle: ->
  #               'app.admin.actions.assets.deleteType'
  #             deleteBoxBody: ->
  #               'app.admin.actions.assets.deleteType'
  #             AddBoxTitle: ->
  #               'app.admin.actions.assets.addType'
  #             editBoxTitle: ->
  #               'app.admin.actions.assets.editType'
  #             loadFilterParams: ->
  #               {}
